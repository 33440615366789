export default {
	EPNS_SDK_EMBED_NAMESPACE: '[EPNS_SDK_EMBED]',
	EPNS_SDK_EMBED_APP_URL: 'https://ethereum-push-notification-service.github.io/embed-dapp',
	EPNS_SDK_EMBED_API_URL: 'https://backend-kovan.epns.io/apis/feeds/get_feeds',
	EPNS_SDK_EMBED_VIEW_ROOT: 'EPNS_SDK_EMBED_VIEW_ROOT',
	EPNS_SDK_EMBED_STYLE_TAG_ID_PREFIX: 'EPNS_SDK_EMBED_STYLE_TAG_ID_',
	EPNS_SDK_EMBED_IFRAME_ID: 'EPNS_SDK_EMBED_IFRAME_ID',
	EPNS_SDK_EMBED_CSS_ZINDEX_MAX: 2147483638, // MAX value
	EPNS_SDK_EMBED_CHANNEL: 'EPNS_SDK_EMBED_CHANNEL',
	EPNS_SDK_EMBED_CHANNEL_TOPIC_IFRAME_APP_LOADED: 'EPNS_SDK_EMBED_CHANNEL_TOPIC_IFRAME_APP_LOADED',
	EPNS_SDK_EMBED_CHANNEL_TOPIC_IFRAME_APP_CLOSED: 'EPNS_SDK_EMBED_CHANNEL_TOPIC_IFRAME_APP_CLOSED',
	EPNS_SDK_EMBED_CHANNEL_TOPIC_SDK_CONFIG_INIT: 'EPNS_SDK_EMBED_CHANNEL_TOPIC_SDK_CONFIG_INIT',
	EPNS_SDK_EMBED_LOCAL_STORAGE_PREFIX: 'EPNS_SDK_EMBED_LOCAL_STORAGE_'
}